import styled from "@emotion/styled"

export const Subheading = styled.h2`
  font-family: oswald, sans-serif;
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 3rem;
`

export const Header1 = styled.h1`
  font-family: oswald, sans-serif;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 400;
  @media (max-width: 576px) {
    font-size: 3rem;
  }

  @media (max-width: 450px) {
    font-size: 2rem;
  }
`

export const Header2 = styled.h2`
  font-family: oswald, sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 400;

  @media (max-width: 450px) {
    font-size: 1rem;
  }
`

export const Paragraph = styled.p`
  font-family: roboto, sans-serif;
  font-size: 1rem;
  font-weight: 300;
`
