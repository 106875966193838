import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ContentContainer from "../components/contentContainer"
import FluidContainer from "../components/fluidContainer"
import styled from "@emotion/styled"
import { Header2, Header1, Subheading, Paragraph } from "../components/headings"
import debounce from "lodash/debounce"
import SEO from "../components/seo"

const ListItem = styled.li`
  list-style: none;
  padding: 1rem 0;
  color: #333;
  flex-basis: 50%;
  a {
    text-decoration: none;
  }

  border-bottom: 1px dashed gray;
  @media (max-width: 576px) {
    flex-basis: 100%;
  }
`

const ListContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  @media (max-width: 576px) {
    display: block;
  }
`

const Content = styled.span``

const filterData = (e, setRecords) => {
  const query = e.target.value
  const fetchData = () => {
    return fetch(`${process.env.GATSBY_API_URL}/records?food_contains=${query}`)
      .then(resp => resp.json())
      .then(resp =>
        resp.map(r => ({
          node: {
            ...r,
          },
        }))
      )
      .then(resp => setRecords(resp))
  }
  return debounce(fetchData, 1000)()
}

const Records = ({ data }) => {
  const [records, setRecords] = useState(data.allStrapiRecords.edges)
  return (
    <Layout>
      <SEO title="Records" keywords={[`eating`, `contests`, `official`]} />
      <FluidContainer>
        <ContentContainer>
          <ListContainer>
            <Header1
              style={{ color: "#333", padding: "2rem", textAlign: "center" }}
            >
              Records
            </Header1>
            <Subheading style={{ color: "black", marginBottom: "0rem" }}>
              Search:
            </Subheading>
            <br />
            <input
              type="text"
              style={{ fontfamily: "roboto", padding: "0.3rem" }}
              onChange={e => filterData(e, setRecords)}
            />
            <List>
              {records.map(record => {
                return (
                  <ListItem>
                    <Paragraph>
                      <strong>{record.node.food}</strong>
                    </Paragraph>
                    <Paragraph>{record.node.description}</Paragraph>
                    <Paragraph>{record.node.time}</Paragraph>
                    {record.node.profile && (
                      <Link to={`/eaters/${record.node.profile.sn}`}>
                        <Paragraph>
                          {record.node.profile.nameF}{" "}
                          {record.node.profile.nameL}
                        </Paragraph>
                      </Link>
                    )}
                  </ListItem>
                )
              })}
            </List>
          </ListContainer>
        </ContentContainer>
      </FluidContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query RecordsQuery {
    allStrapiRecords {
      edges {
        node {
          food
          description
          time
          profile {
            id
            sn
            nameF
            nameL
          }
        }
      }
    }
  }
`
export default Records
